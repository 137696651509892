<template>
  <div class="project hedifys-21">
    <form ref="project" @submit.prevent="save">
      <!-- BREADCRUMBS -->
      <div class="breadcrumbs grid-x row">
        <div class="cell auto">
          <breadcrumb
            v-if="project && project.reference && project.projectId"
            :items="[
              { route: { name: 'projects'}, name: 'Chantiers'},
              { route: { name: 'projects-projectId', params: { projectId: $route.params.projectId }}, name: `${project.reference} ${customerName}`}
            ]"
          />
        </div>
      </div>
      <!-- HEADER / TITRE -->
      <div class="header grid-x" :class="{ 'includes-tabs': $route.params.projectId }">
        <div class="cell auto">
          <h1>{{ project && project.reference && project.projectId ? `${project.reference} ${customerName}` : project && !project.projectId ? 'Nouveau projet' : ' ' }}</h1>
        </div>
        <div class="cell shrink" v-if="!isLoading">
          <app-button @click="openDeleteModal" theme="warning" size="large" class="generate">Supprimer</app-button>
        </div>
        <div class="cell shrink save" v-if="!isLoading">
          <app-button v-if="project && project.status === 'PENDING'" @click="saveAndGo" theme="primary" size="large" class="generate">Enregistrer et générer le planning</app-button>
          <app-button v-else-if="project && project.status !== 'PENDING'" theme="primary" size="large" type="submit">{{ $route.params.projectId ? 'Enregistrer' : 'Ajouter' }}</app-button>
        </div>
      </div>
      <!-- TABS -->
      <tab-nav v-if="project && project.projectId" :tabs="tabs" />
      <!-- BODY / FORMULAIRE -->
      <div class="body" v-if="!isLoading && project">
      <section class="hedifys-chantier" id="project">
        <form @submit.prevent="save" v-if="project">
          <vk-grid gutter="medium">
            <!-- Cadre de gauche -->
            <div class="house tile uk-width-3-5">
              <div class="uk-height-1-1">
                <vk-grid>
                  <div class="uk-width-2-3">
                    <h3>Maison</h3>
                    <label>Modèle *</label>
                    <input class="uk-input" type="text" v-model="project.model">
                  </div>
                  <div class="uk-width-1-3">
                    <h3>N° Dossier</h3>
                    <label>Référence du dossier *</label>
                    <input class="uk-input" type="text" v-model="project.reference">
                  </div>
                  <div class="uk-width-1-1">
                    <h3>Terrain</h3>
                    <vk-grid class="uk-child-width-expand" gutter="medium">
                      <div class="uk-width-2-3">
                        <label>Adresse *</label>
                        <input class="uk-input" type="text" v-model="project.address.addressLine1" required />
                      </div>
                    </vk-grid>
                    <vk-grid class="uk-child-width-expand" gutter="medium">
                      <div class="uk-width-2-3">
                        <div>
                          <vk-grid gutter="small" class="uk-child-width-expand">
                            <div class="select uk-width-1-4@m">
                              <label>Code postal *</label>
                              <input class="uk-input" type="text" v-model="project.address.postalCode" required  maxlength="7" />
                            </div>
                            <div >
                              <label>Commune *</label>
                              <input class="uk-input" type="text" v-model="project.address.city" required/>
                            </div>
                        </vk-grid>
                      </div>
                    </div>
                  </vk-grid>
                  </div>
                </vk-grid>
              </div>
            </div>
            <!-- Cadres de droite -->
            <div class="customer uk-width-expand">
              <vk-grid class="tile uk-child-width-1-1 uk-height-1-1" gutter="collapse">
                <div :class="selectedCustomer ? 'exist' : ''">
                  <vk-grid class="uk-child-width-expand">
                    <div><h3>Client</h3></div>
                    <div v-if="$route.params && $route.params.projectId && selectedCustomer" class="uk-text-right">
                      <button><router-link :to="{name: 'projects-customers-customerId', params: { customerId: selectedCustomer.customerId }}">Consulter</router-link></button>
                    </div>
                  </vk-grid>
                  <template v-if="$route.params && $route.params.projectId && selectedCustomer">
                    <p class="self">{{ selectedCustomer.firstname }} {{ selectedCustomer.lastname }} </p>
                    <p class="partner">{{ selectedCustomer.secondaryContactFirstname }} {{ selectedCustomer.secondaryContactLastname }} </p>
                    <p class="address" v-if="selectedCustomer.address">
                      {{ selectedCustomer.address.addressLine1 }} <br>
                      {{ selectedCustomer.address.postalCode }} {{ selectedCustomer.address.city }}
                    </p>
                    <p class="phone">{{ selectedCustomer.phone }}</p>
                    <p class="email">{{ selectedCustomer.email }}</p>
                  </template>
                  <template v-else>
                    <app-button @click="isSelectCustomerModalOpen = true" size="small">Rechercher un client existant</app-button>
                    <input class="uk-input" v-if="selectedCustomer" type="text" :value="`${selectedCustomer.firstname} ${selectedCustomer.lastname}`" disabled>
                    <span>ou </span><router-link :to="{name: 'projects-customers-new'}">Créer un nouveau client</router-link>
                  </template>
                </div>
                <div class="uk-margin-top">
                  <h3>Suivi</h3>
                  <label>Conducteur</label>
                  <p v-if="selectedConductor == null">Conducteur introuvable</p>
                  <select class="uk-select" v-model="selectedConductor">
                    <option :value="conductor.memberId" v-for="(conductor, index) in conductors" :key="`project-conductor-${index}`">
                      {{ conductor.lastname }} {{ conductor.firstname }}
                    </option>
                  </select>
                </div>
              </vk-grid>
            </div>
            <!-- Cadre bas signature contrat -->
            <div class="contract tile uk-width-1-1">
              <div>
                <h3>Signature contrat</h3>
                <vk-grid class="uk-child-width-expand" gutter="medium">
                  <div>
                    <label>Date de signature du contrat</label>
                    <datepicker format="dd MMMM yyyy" v-model="project.contractSignatureDate" :language="french"/>
                  </div>
                  <div>
                    <label>Date limite de livraison</label>
                    <input class="uk-input" :value="project.contractSignatureDate ? $dayjs(project.contractSignatureDate).add(15, 'M').format('DD MMMM YYYY') : ''" format="dd MMMM yyyy" :language="french" disabled/>
                  </div>
                  <div></div>
                </vk-grid>
              </div>
            </div>
            <!-- Cadre bas permit de construire -->
            <div class="permit tile uk-width-1-1">
              <div>
                <h3>Permis de construire</h3>
                <vk-grid class="uk-child-width-expand" gutter="medium">
                  <div>
                    <label>Date de dépôt</label>
                    <datepicker format="dd MMMM yyyy" v-model="project.constructionPermitSubmissionDate" :language="french"/>
                  </div>
                  <div>
                    <label>Date prévisionnelle d'acceptation</label>
                    <datepicker format="dd MMMM yyyy" v-model="project.constructionPermitPlannedDate" :language="french"/>
                  </div>
                  <div>
                    <label>Date d'acceptation</label>
                    <datepicker format="dd MMMM yyyy" v-model="project.constructionPermitObtentionDate" :language="french"/>
                  </div>
                </vk-grid>
              </div>
            </div>
            <!-- Cadre bas chantier -->
            <div class="project tile uk-width-1-1">
              <div>
                <h3>Chantier <span v-if="projectSteps.length > 0 && (project.status === 'PENDING' || project.status === 'TODO')" class="delete" @click="show = !show">Supprimer</span></h3>
                <vk-grid class="uk-child-width-expand" gutter="medium">
                  <div class="status" v-if="project.status">
                    <label>Statut</label>
                    <p :class="project.status.toLowerCase()">{{ statuses[project.status] }}</p>
                  </div>
                  <div class="buildfilter uk-width-1-3">
                    <label>Filtrer</label>
                    <select class="uk-select" v-model="selectedBrandAgency" :disabled="projectSteps.length > 0 && (project.status === 'PENDING' || project.status === 'TODO' || project.status === 'DONE')">
                      <option :value="null">Tout</option>
                      <optgroup label="Marques">
                        <template v-for="(element, index) in filterBrandsAgencies">
                          <option v-if="element.type === 'brand'"
                            :value="{id: element.id, name: element.name, type: element.type}"
                            :key="`setting-build-filters-brand-option-${index}`">{{ element.name }}
                          </option>
                        </template>
                      </optgroup>
                      <optgroup label="Agences">
                        <template v-for="(element, index) in filterBrandsAgencies">
                          <option v-if="element.type === 'agency'"
                            :value="{id: element.id, name: element.name, type: element.type}"
                            :key="`setting-build-filters-agency-option-${index}`">{{ element.name }}
                          </option>
                        </template>
                      </optgroup>
                    </select>
                  </div>
                  <div class="buildfilter uk-width-1-3">
                    <label>Gabarits du plan de tâches</label>
                    <select class="uk-select" v-model="selectedBuildFilter" :disabled="projectSteps.length > 0 && (project.status === 'PENDING' || project.status === 'TODO' || project.status === 'DONE')">
                      <option :value="null">Sélectionner un gabarit</option>
                      <option v-for="buildFilterRow in filteredBuildFilters"
                        :value="buildFilterRow.buildFilterId"
                        :key="buildFilterRow.buildFilterId">
                        {{ buildFilterRow.label }}
                      </option>
                    </select>
                  </div>
                  <template v-if="project.status && project.status === 'PENDING' && project.constructionPermitPlannedDate">
                    <hr class="uk-width-1-1 uk-margin-left">
                    <div >
                      <label>Date prévisionnelle d'ouverture de chantier</label>
                      <datepicker format="dd MMMM yyyy" :value="$dayjs(project.constructionPermitPlannedDate).add(60, 'days').startOf('isoWeek').format('YYYY-MM-DD')" :language="french" disabled/>
                    </div>
                  </template>
                  <template v-if="project.status && project.status !== 'PENDING'">
                    <hr class="uk-width-1-1 uk-margin-left">
                    <div >
                      <label>Date prévisionnelle d'ouverture</label>
                      <datepicker v-if="project.estimatedStartDate" format="dd MMMM yyyy" :value="project.estimatedStartDate" :language="french" disabled/>
                      <input
                        v-else-if="projectSteps && projectSteps.length > 0 && projectSteps.filter(ps => ps.type === 'START')[0].startedAt"
                        class="uk-input"
                        type="date"
                        :value="$dayjs(projectSteps.filter(ps => ps.type === 'START')[0].startedAt).format('YYYY-MM-DD')" disabled>
                      <input v-else class="uk-input" type="date" :value="null" disabled>
                    </div>
                    <div >
                      <label>Objectif de durée</label>
                      <input
                        v-if="project.estimatedCloseDate && project.estimatedStartDate"
                        class="uk-input"
                        type="text"
                        :value="`${ ($dayjs(project.estimatedCloseDate).diff($dayjs(project.contractSignatureDate ? project.contractSignatureDate : project.estimatedStartDate), 'days') + 1) } J`"
                        disabled>
                      <input
                        v-else-if="projectSteps && projectSteps.length > 0 && projectSteps.filter(ps => ps.type === 'START')[0].startedAt"
                        class="uk-input"
                        type="text"
                        :value="`${$dayjs(projectSteps.filter(ps => ps.type === 'CLOSE')[0].startedAt).diff($dayjs(projectSteps.filter(ps => ps.type === 'START')[0].startedAt), 'days') + 1}J`"
                        disabled>
                      <input
                        v-else
                        class="uk-input"
                        type="text"
                        :value="null"
                        disabled>
                    </div>
                    <div >
                      <label>Objectif de livraison</label>
                      <datepicker v-if="project.estimatedCloseDate" format="dd MMMM yyyy" :value="project.estimatedCloseDate" :language="french" disabled/>
                      <input
                        v-else-if="projectSteps && projectSteps.length > 0 && projectSteps.filter(ps => ps.type === 'CLOSE')[0].closedAt"
                        class="uk-input"
                        type="date"
                        :value="$dayjs(projectSteps.filter(ps => ps.type === 'CLOSE')[0].closedAt).format('YYYY-MM-DD')"
                        disabled>
                      <input
                        v-else
                        class="uk-input"
                        type="date"
                        :value="null"
                        disabled>
                    </div>
                  </template>
                  <template v-if="(project.status === 'IN_PROGRESS' || project.status === 'DONE') && projectSteps && projectSteps.length > 0">
                    <hr class="uk-width-1-1 uk-margin-left">
                    <div >
                      <vk-grid class="uk-child-width-expand" gutter="medium">
                        <div>
                          <label>Date d'ouverture</label>
                          <datepicker v-if="projectSteps.filter(ps => ps.type === 'START')[0].startedAt" format="dd MMMM yyyy" :value="projectSteps.filter(ps => ps.type === 'START')[0].startedAt" :language="french" disabled/>
                        </div>
                      </vk-grid>
                    </div>
                    <div >
                      <label>Durée</label>
                      <input class="uk-input" type="text" :value="`${getProjectDuration()} J`" disabled>
                    </div>
                    <div >
                      <vk-grid class="uk-child-width-expand" gutter="medium">
                        <div>
                          <label>Date de livraison</label>
                          <datepicker
                            v-if="projectSteps.filter(ps => ps.type === 'CLOSE')[0].closedAt"
                            format="dd MMMM yyyy"
                            :value="projectSteps.filter(ps => ps.type === 'CLOSE')[0].closedAt"
                            :language="french"
                            disabled/>
                        </div>
                        <div v-if="project.status !== 'PENDING' && project.status !== 'TODO'" class="uk-width-small">
                          <label>Retard</label>
                          <input class="uk-input" type="text" :value="`${getCloseDateDelay()} J`" disabled>
                        </div>
                      </vk-grid>
                    </div>
                  </template>
                </vk-grid>
              </div>
            </div>
          </vk-grid>
        </form>

        <vk-modal class="project-modal" :show.sync="show" center>
          <vk-modal-title>Supprimer les étapes du chantiers</vk-modal-title>
          <vk-grid gutter="medium">
            <div class="uk-width-1-1">
              Vous êtes sur le point de supprimer toutes les étapes de ce chantier, souhaitez-vous continuer ?
            </div>
            <div class="uk-width-1-2">
              <button @click="clickCustomerDelete">Oui</button>
            </div>
            <div class="uk-width-1-2">
              <button @click="show =false">Non</button>
            </div>
            <div class="uk-width-1-1">
              <p>Info: Si vous souhaitez générer des tâches à partir d'un autre gabarit, vous pouvez changer ce dernier dans les paramètres onget "Projet" puis regénérer un planning dans l'onglet "Planning"'</p>
            </div>
          </vk-grid>
        </vk-modal>
      </section>
      <SelectCustomerModal :show="isSelectCustomerModalOpen" @update:show="isSelectCustomerModalOpen = false" v-model="selectedCustomer" />
      </div>
      <div class="body" v-else>
        <div class="card spinner-container">
          <app-spinner />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import TabNav from '@/components/layouts/TabNav.vue';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';
import SelectCustomerModal from '@/components/general/SelectCustomerModal.vue';

import Datepicker from 'vuejs-datepicker';
import { fr } from 'vuejs-datepicker/dist/locale';

import project from '@/services/api/project';
import customer from '@/services/api/customer';
import buildFilter from '@/services/api/buildFilter';
import member from '@/services/api/member';
import projectStep from '@/services/api/projectStep';
import statuses from '@/services/data/statuses.json';

export default {
  name: 'project',
  components: {
    TabNav,
    Breadcrumb,
    Datepicker,
    SelectCustomerModal,
  },
  metaInfo() {
    return {
      // eslint-disable-next-line no-nested-ternary
      title: this.project && this.project.reference && this.project.projectId ? `${this.project.reference} ${this.customerName} – Chantier` : (this.project && !this.project.projectId ? 'Nouveau projet' : 'Chantier'),
    };
  },
  data() {
    return {
      tabs: [
        {
          route: {
            name: 'projects-projectId',
            params: { projectId: this.$route.params.projectId },
          },
          label: 'Informations',
        },
        {
          route: {
            name: 'projects-projectId-steps',
            params: { projectId: this.$route.params.projectId },
          },
          label: 'Tâches',
        },
        {
          route: {
            name: 'projects-projectId-notes',
            params: { projectId: this.$route.params.projectId },
          },
          label: 'Notes',
        },
      ],
      isLoading: null,

      statuses,
      project: null,
      projectSteps: [],
      selectedCustomer: null,
      selectedConductor: null,
      selectedBuildFilter: null,
      customers: [],
      conductors: [], // Membres avec le type conductor
      buildFilters: [],
      filteredCustomers: [],
      isSelectCustomerModalOpen: false,
      show: false,

      // Datepicker
      french: fr,

      filteredBuildFilters: [],
      filterBrandsAgencies: [],
      selectedBrandAgency: null,

      me: null,
      isBrandAdmin: null,
    };
  },
  watch: {
    $route: 'getData',

    selectedBrandAgency() {
      this.selectedBuildFilter = null;
      this.filterBuildFiltersByAgency();
    },
    selectedCustomer() {
      if (this.selectedCustomer) {
        this.isSelectCustomerModalOpen = false;
      }
    },
  },
  computed: {
    customerName() {
      if (this.project && this.project.customer) {
        return this.project.customer.type === 'COMPANY' ? this.project.customer.companyName : customer.getCustomersNames(this.project.customer);
      }
      return '';
    },
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    openDeleteModal() {
      this.$message.show({
        title: 'Supprimer le projet',
        text: 'Êtes vous sûr de vouloir supprimer ce projet ?',
        confirmText: 'Supprimer le projet',
        hasConfirm: true,
        cancelText: 'Annuler',
        hasCancel: true,
        onConfirm: () => {
          this.deleteProject();
        },
      });
    },
    async deleteProject() {
      try {
        await project.delete(this.$route.params.projectId);
        this.$notification.show({ text: 'Ce projet a été supprimée avec succès !' });

        this.$router.push({ name: 'projects' });
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la suppression du projet',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getData() {
      this.isLoading = true;
      await this.getMe();
      await this.getBuildFilters();
      this.filteredBuildFilters = this.buildFilters;
      await this.getConductors();

      if (this.$route && this.$route.params.projectId) {
        await this.getProject();
        await this.getCustomer(this.project.customer.customerId);
        const projectConductor = this.conductors.filter((conductor) => this.project.member.memberId === conductor.memberId);
        this.selectedConductor = projectConductor.length > 0 ? projectConductor[0].memberId : null;
        await this.getProjectSteps();
        if (this.project.buildFilter.buildFilterId) {
          const buildFilters = await this.buildFilters.find((b) => this.project.buildFilter.buildFilterId === b.buildFilterId);
          // Si on ne trouve pas le build-filter,
          // on en ajoute 1 et on le sélectionne, avec le label "non trouvé"
          if (buildFilters) {
            this.selectedBuildFilter = buildFilters.buildFilterId;
          } else {
            this.selectedBuildFilter = this.project.buildFilter.buildFilterId;
            this.filteredBuildFilters.push({
              buildFilterId: this.project.buildFilter.buildFilterId,
              label: 'Indéfini',
            });
          }
        }
      } else {
        if (this.$route.params.customerId) {
          await this.getCustomer(this.$route.params.customerId);
        }
        this.project = {
          reference: null,
          model: null,
          constructionPermitSubmissionDate: null,
          constructionPermitPlannedDate: null,
          constructionPermitObtentionDate: null,
          address: {
            addressLine1: null,
            city: null,
            postalCode: null,
          },
        };
        this.selectedConductor = this.conductors[0].memberId;
      }

      this.getFilterAgencies();
      this.isLoading = false;
    },
    async getProject() {
      try {
        this.project = await project.getById(this.$route.params.projectId);
      } catch (error) {
        this.$displayNotification('L\'obtention des données du projet a échouée.');
        this.$message.show({
          title: 'Erreur',
          text: 'L\'obtention des données du projet a échouée.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getProjectSteps() {
      try {
        this.projectSteps = await projectStep.getByProject(this.$route.params.projectId);
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'L\'obtention des données des étapes du projet a échouée.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getCustomer(customerId) {
      try {
        this.selectedCustomer = await customer.getById(customerId);
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'L\'obtention des données du client a échouée.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getBuildFilters() {
      try {
        const buildFilters = await buildFilter.getAll();

        if (buildFilters && buildFilters.length > 0) {
          this.buildFilters = buildFilters.sort((a, b) => a.label.localeCompare(b.label));
        } else {
          this.buildFilters = [];
        }
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'L\'obtention des données des gabarits a échouée.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    getFilterAgencies() {
      this.filterBrandsAgencies = [];
      this.buildFilters.forEach((currentBuildFilter) => {
        if (!this.filterBrandsAgencies.find((bf) => (currentBuildFilter.brandId === bf.id && !currentBuildFilter.agencyId) || currentBuildFilter.agencyId === bf.id)) {
          this.filterBrandsAgencies.push({
            id: currentBuildFilter.agencyId ? currentBuildFilter.agencyId : currentBuildFilter.brandId,
            name: currentBuildFilter.agencyId ? currentBuildFilter.agencyName : currentBuildFilter.brandName,
            type: currentBuildFilter.agencyId ? 'agency' : 'brand',
          });
        }
      });
    },
    filterBuildFiltersByAgency() {
      if (!this.selectedBrandAgency) this.filteredBuildFilters = this.buildFilters;
      else if (this.selectedBrandAgency.type === 'brand') this.filteredBuildFilters = this.buildFilters.filter((element) => (element.brandId && !element.agencyId) && element.brandId === this.selectedBrandAgency.id);
      else if (this.selectedBrandAgency.type === 'agency') this.filteredBuildFilters = this.buildFilters.filter((element) => element.brandId && element.agencyId === this.selectedBrandAgency.id);
    },
    async getConductors() {
      try {
        const allConductors = await member.getAll();

        if (allConductors && allConductors.length > 0) {
          this.conductors = allConductors.sort((a, b) => a.lastname.localeCompare(b.lastname));
        } else {
          this.conductors = [];
        }
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'L\'obtention des données des conducteurs a échouée.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    handleDate(target, value) {
      if (value) this.project[target] = this.$dayjs(value).format();
      else this.project[target] = null;
    },
    filterCustomers(event) {
      if (event.target && event.target.value) {
        this.filteredCustomers = this.customers.filter((c) => c.firstname.toLowerCase().includes(event.target.value) || c.lastname.toLowerCase().includes(event.target.value));
      } else {
        this.filteredCustomers = this.customers;
      }
    },
    async clickCustomerDelete() {
      try {
        await projectStep.deleteAllByProjectId(this.project.projectId);
        await this.getData();
        this.$notification.show({ text: 'Suppression des tâches du projet réussie.' });
        this.show = false;
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'La suppression des tâches du projet a échouée.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    getProjectDuration() {
      const startStep = this.projectSteps.filter((ps) => ps.type === 'START')[0];
      const closeStep = this.projectSteps.filter((ps) => ps.type === 'CLOSE')[0];
      return this.$dayjs(closeStep.startedAt).diff(this.$dayjs(this.project.contractSignatureDate ? this.project.contractSignatureDate : startStep.startedAt), 'days') + 1;
    },
    getStartDateDelay() {
      const startStep = this.projectSteps.filter((ps) => ps.type === 'START')[0];
      return this.$dayjs(startStep.startedAt).diff(this.$dayjs(this.project.estimatedStartDate), 'days');
    },
    getCloseDateDelay() {
      const closeStep = this.projectSteps.filter((ps) => ps.type === 'CLOSE')[0];
      return this.$dayjs(closeStep.startedAt).diff(this.$dayjs(this.project.estimatedCloseDate), 'days');
    },
    async saveAndGo() {
      await this.save();
      await this.getData();
      this.$router.push({ name: 'projects-projectId-steps', params: { projectId: this.project.projectId } });
    },
    async save() {
      this.isLoading = true;
      delete this.project.customer;
      delete this.project.buildFilter;
      delete this.project.agency;
      delete this.project.member;
      delete this.project.status;
      delete this.project.duration;
      delete this.project.startedStepDate;
      delete this.project.closedStepDate;
      delete this.project.initialDuration;
      delete this.project.delay;

      const currentProject = await {
        ...this.project,
        customerId: null,
        memberId: this.selectedConductor,
        buildFilterId: this.selectedBuildFilter,
      };

      if (this.selectedCustomer) {
        currentProject.customerId = this.selectedCustomer.customerId;
      } else {
        this.$message.show({
          title: 'Erreur',
          text: 'Vous devez sélectionner un client pour créer un projet.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }

      if (this.$route.params && this.$route.params.projectId && this.selectedCustomer) {
        try {
          if (!this.project.address.city || !this.project.address.addressLine1.trim() || !this.project.address.postalCode) throw new Error('L\'adresse n\'est pas valide. Valeurs attendues: adresse, code postal et ville.');
          await project.update(currentProject);
          await this.getData();
          this.$notification.show({ text: 'Ce projet a été modifiée avec succès !' });
        } catch (error) {
          this.$message.show({
            title: 'Erreur',
            text: 'La mise à jour du projet a échouée.',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
      } else if (this.selectedCustomer) {
        try {
          if (!this.project.address.city || !this.project.address.addressLine1.trim() || !this.project.address.postalCode) throw new Error('L\'adresse n\'est pas valide. Valeurs attendues: adresse, code postal et ville.');
          const newProject = await project.create(currentProject);
          this.$notification.show({ text: 'Ce projet a été créé avec succès !' });

          this.tabs = this.tabs.map((tab) => ({
            ...tab,
            route: {
              ...tab.route,
              params: {
                ...tab.route.params,
                projectId: newProject.projectId,
              },
            },
          }));
          this.$router.push({ name: 'projects-projectId', params: { projectId: newProject.projectId } });
        } catch (error) {
          this.$message.show({
            title: 'Erreur',
            text: 'La création du projet a échouée.',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
      }
      this.isLoading = false;
    },
    async getMe() {
      try {
        this.me = await member.getMe();
        this.isBrandAdmin = this.me.isBrandAdmin;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du membre',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
  },
};
</script>

<style lang='sass' scoped>
.project
  .body
    @include screen
  .header
    @include header
  .row
    @include row
  .breadcrumbs
    @include breadcrumbs
  .spinner-container
    @include spinner-container
  .card
    @include card
  h3
    color: $primary
    margin: 0 16px 32px 0px
  .radio-container
    width: 50%
  .save
    margin-left: 16px
</style>

<style lang="scss">
#project {
  .tile {
    > div {
      padding: 30px;
      border-radius: $global-border-radius;
      box-shadow: 1px 1px 1px 1px rgba(0,0,0, 0.1);
      background-color: #ffffff;
    }
  }
  p {
    color: $blue-medium;
  }
  label {
    display: block;
    padding-bottom: 0.3rem;
    @include heading-xs;
    color: $label;
  }
  input, select {
    height: 35px;
    width: 100%;
    border-radius: $global-border-radius;
    border: 1px solid $gray-light;
  }
  .vdp-datepicker input {
    padding-left: 0.8rem;
    color: $gray-medium;
  }
  .customer {
    span {
      display: inline-block;
      padding: 0.5rem 0.5rem 0.5rem 0;
      font-weight: 500;
    }
    a {
      font-weight: 500;
      color: $blue-medium;
    }
    button:not(.app-button) {
      padding: 0;
      height: 35px;
      border: none;
      background-color: transparent;
      a {
        padding: 10px 25px;
        color: $gray-regular;
        text-transform: uppercase;
        border-radius: $global-border-radius;
        background-color: $gray-white;
      }
    }
    .uk-input {
      margin: 1rem 0
    }
    .exist {
      p {
        margin: 0;
      }
      .self, .partner, .address, .phone, .email {
        color: $gray-dark;
        font-weight: 400;
      }
      .self, .partner {
        font-weight: 500;
      }
      .address, .phone, .email {
        color: $gray-regular;
      }
    }
    .search-input {
      margin: 0 20px 20px 0;
      border-radius: $global-border-radius;
      padding-left: 20px;
    }
    .search {
      position: relative;
      margin: 20px 0
      p {
        cursor: pointer;
      }
      .customers {
        top: 42px;
        position: absolute;
        padding: 10px 0;
        width: 100%;
        left: 0;
        box-shadow: 1px 1px 1px 1px rgba(0,0,0, 0.1);
        background-color: #ffffff;
        p {
          display: block;
          padding: 5px 20px;
          color: $gray-dark;
        }
      }
    }
  }
  .project {
    > div > p {
      position : relative;
    }
    .delete {
      cursor: pointer;
      @include paragraph;
      color: $white;
      background-color: $error;
      padding: 10px 10px;
      border-radius: $global-border-radius;
      margin-left: 10px;
    }
    .status {
      p {
        margin: 0;
        padding: 5px 10px;
        max-width: 150px;
        border-width: 3px;
        border-style: solid;
        text-transform: uppercase;
        text-align: center;
        font-weight: 500;
        &.pending {
          color: $gray-regular;
          border-color: $gray-regular;
        }
        &.todo {
          color: $orange;
          border-color: $orange;
        }
        &.in-progress {
          color: $blue-medium;
          border-color: $blue-medium;
        }
        &.done {
          color: $green;
          border-color: $green;
        }
      }
    }
  }
  .save {
    margin: 30px 0 0 0;
    padding: 20px;
    text-align: right;
    background-color: $blue-medium;
    margin-bottom: 150px;
    .generate {
      margin-left: 10px!important;
    }
    button {
      padding: 10px 15px;
      border-radius: $global-border-radius;
      border: none;
      color: $blue-medium;
      font-weight: 500;
      background-color: #ffffff;
      text-transform: uppercase;
    }
  }
}
.project-modal {
  .uk-grid {
    padding: 30px!important;
    button {
      padding: 10px 0;
      width: 100%;
      color: #ffffff;
      border: none;
      background-color: $blue-medium;
    }
  }
}
</style>
