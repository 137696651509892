<template>
  <div class="customer hedifys-21">
    <form ref="customer" @submit.prevent="save">
      <!-- BREADCRUMBS -->
      <div class="breadcrumbs grid-x row">
        <div class="cell auto">
          <breadcrumb
            v-if="$route.params.customerId && customer && customer.lastname"
            :items="[
              {
                route: {
                  name: routeStatus
                },
                name: routeStatus  === 'customers-unqualified'? 'À qualifier' : routeStatus === 'customers-prospects' ? 'Prospects' : 'Clients'
              },
              { route: { name: `${routeStatus}-customerId`,
                  params: { customerId: $route.params.customerId }},
                name: `${customer.firstname} ${customer.lastname}`
              }
            ]"
          />
        </div>
      </div>
      <!-- HEADER / TITRE -->
      <div class="new-header grid-x" :class="{ 'includes-tabs': $route.params.customerId }">
        <div class="cell shrink">
          <h1>{{ $route.params.customerId && customer && customer.lastname ? `${customer.firstname} ${customer.lastname}` : 'Ajouter un contact' }}</h1>
        </div>
        <div class="cell auto tag-container">
          <h5 class="tag" :class="tagColor">{{ getCustomerStatusName(customer.status) }}</h5>
        </div>
        <div class="cell shrink" v-if="$route.params.customerId">
          <app-button theme="warning" size="large" @click="openDeleteModal">Archiver</app-button>
        </div>
        <div class="cell shrink save">
          <app-button v-if="$route.params.customerId" theme="primary" size="large" type="submit">Sauvegarder</app-button>
          <app-button v-else theme="primary" size="large" type="submit">Ajouter</app-button>
        </div>
      </div>
      <!-- TABS -->
      <tab-nav :tabs="tabs" v-if="$route.params.customerId" />
      <!-- BODY / FORMULAIRE -->
      <div class="body" v-if="!isLoading && customer">
        <section class="hedifys-chantier customer-page" v-if="customer">
          <vk-grid gutter="medium" class="wrapper uk-child-width-expand uk-padding uk-margin-remove-left">
            <vk-grid gutter="small" class="uk-child-width-1-1">
              <div class="uk-width-1-1@m">
                <h3>Informations CRM</h3>
              </div>
              <div class="uk-width-1-4@m">
                <vk-grid class="uk-child-width-1-1" gutter="small">
                  <div class="form-section">
                    <vk-grid gutter="small" class="uk-child-width-expand">
                      <div class="uk-width-1-1">
                        <label>Statut *</label>
                        <select class="uk-select gender" v-model="customer.status" required>
                          <option :value="status.name" v-for="status in customerStatuses" :key="status.name">
                            {{ status.label }}
                          </option>
                        </select>
                      </div>
                    </vk-grid>
                  </div>
                </vk-grid>
              </div>
              <div class="uk-width-1-4@m">
                <vk-grid class="uk-child-width-1-1" gutter="small">
                  <div class="form-section">
                    <vk-grid gutter="small" class="uk-child-width-expand">
                      <div class="uk-width-1-1">
                        <label>Origine du contact</label>
                        <span class="info" v-if="customer.firstContact && customerFirstContacts.find((c) => c.name === customer.firstContact)">
                           {{ customerFirstContacts.find((c) => c.name === customer.firstContact).label }}
                        </span>
                        <span class="info" v-else>Inconnue</span>
                      </div>
                    </vk-grid>
                  </div>
                </vk-grid>
              </div>
              <div class="uk-width-1-4@m" v-if="$route.params.customerId && customer.firstContact === 'MANUAL_AGENCY_CREATION'">
                <vk-grid class="uk-child-width-1-1" gutter="small">
                  <div class="form-section">
                    <vk-grid gutter="small" class="uk-child-width-expand">
                      <div class="uk-width-1-1">
                        <label>Origine avancée du contact</label>
                        <span class="info" v-if="customer.brandKnowledge && customerBrandKnowledge.find((c) => c.name === customer.brandKnowledge)">
                            {{ customerBrandKnowledge.find((c) => c.name === customer.brandKnowledge).label }}
                        </span>
                        <span class="info" v-else>Inconnue</span>
                      </div>
                    </vk-grid>
                  </div>
                </vk-grid>
              </div>
              <div class="uk-width-1-4@m" v-if="!$route.params.customerId">
                <label>Origine avancée du contact *</label>
                <select class="uk-select" v-model="customer.brandKnowledge" required>
                  <option :value="brandKnowledge.name"
                    v-for="brandKnowledge in customerBrandKnowledge"
                    :key="brandKnowledge.name">{{ brandKnowledge.label }}
                  </option>
                </select>
              </div>
              <div class="uk-width-1-4@m" v-if="$route.params.customerId">
                <vk-grid class="uk-child-width-1-1" gutter="small">
                  <div class="form-section">
                    <vk-grid gutter="small" class="uk-child-width-expand">
                      <div class="uk-width-1-1">
                        <label>Date de creation</label>
                        <span class="info">
                          {{ $dayjs(customer.createdAt).format('DD/MM/YYYY') }}
                        </span>
                      </div>
                    </vk-grid>
                  </div>
                </vk-grid>
              </div>
              <div class="uk-width-1-1@m">
                <div class="form-section">
                  <vk-grid gutter="small" class="uk-child-width-expand">
                    <div class="uk-width-1-1">
                      <label>Notes</label>
                      <textarea class="uk-textarea" type="text" v-model="customer.notes" rows="5" />
                    </div>
                  </vk-grid>
                </div>
              </div>
              <div class="uk-width-1-2@m">
                  <vk-grid gutter="small" class="uk-child-width-expand">
                    <div class="uk-width-1-1" >
                      <label>Échéance du projet</label>
                      <app-datepicker v-model="customer.deadline" minView="month" size="large" format="MMMM yyyy" />
                    </div>
                    <div class="uk-width-1-1">
                      <label>Budget prévu</label>
                      <input class="uk-input" type="text" v-model="customer.budget" />
                    </div>
                  </vk-grid>
                </div>
                <div class="uk-width-1-2@m">
                  <vk-grid gutter="small" class="uk-child-width-expand">
                    <div class="uk-width-1-1" v-if="customer && customer.agencyId">
                      <app-multi-select
                        label="Communes de construction"
                        v-model="customer.buildingCities"
                        :options="postalCodes"
                        @input="postalCodeChange"
                        @search="searchChange"
                        :disabledSelection="disablePostalCodeSelection"
                        errorMessage="Vous ne pouvez sélectionner que 3 communes maximum !"
                      />
                    </div>
                  </vk-grid>
                </div>
            </vk-grid>
          </vk-grid>
        </section>

        <section class="hedifys-chantier customer-page" id="customer" v-if="customer">
            <vk-grid gutter="medium" class="wrapper uk-child-width-expand uk-padding uk-margin-remove-left">
              <div class="uk-width-1-1@m">
                <vk-grid class="uk-child-width-1-1" gutter="small">
                  <div class="form-section">
                    <h3>Type de contact</h3>
                    <vk-grid gutter="small" class="uk-child-width-expand">
                      <div class="uk-width-1-2">
                        <label>Personne physique ou morale</label>
                        <div class="grid-x radio-container">
                          <div class="cell auto">
                            <app-radio v-model="isPerson" :value="true">Personne physique</app-radio>
                          </div>
                          <div class="cell auto">
                            <app-radio v-model="isPerson" :value="false">Personne morale</app-radio>
                          </div>
                        </div>
                      </div>
                    </vk-grid>
                  </div>
                </vk-grid>
              </div>
              <div class="uk-width-1-2@m">
                <vk-grid class="uk-child-width-1-1" gutter="small">
                  <div class="form-section">
                    <h3>Contact principal</h3>
                    <vk-grid gutter="small" class="uk-child-width-expand">
                      <div class="select uk-width-1-4@m">
                        <label>Civilité</label>
                        <select class="uk-select gender" v-model="customer.gender">
                          <option :value="null">Choisir</option>
                          <option :value="gender.value" v-for="gender in genders" :key="gender.value">
                            {{ gender.label }}
                          </option>
                        </select>
                      </div>
                      <div class="uk-width-3-4@m">
                        <label>Nom *</label>
                        <input class="uk-input" type="text" v-model="customer.lastname" required />
                      </div>
                      <div class="uk-width-1-2">
                        <label>Prénom *</label>
                        <input class="uk-input" type="text" v-model="customer.firstname" required />
                      </div>
                      <div class="uk-width-1-2">
                        <label>Profession</label>
                        <input class="uk-input" type="text" v-model="customer.profession" />
                      </div>
                      <div class="uk-width-1-2">
                        <label>Date de naissance</label>
                        <app-datepicker v-model="customer.birthDate" />
                      </div>
                      <div class="uk-width-1-2">
                        <label>Lieu</label>
                        <input class="uk-input" type="text" v-model="customer.birthPlace" />
                      </div>
                    </vk-grid>
                  </div>
                  <div class="form-section">
                    <h3>Coordonnées</h3>
                    <vk-grid gutter="small" class="uk-child-width-expand">
                      <div class="uk-width-1-1">
                        <label>Téléphone *</label>
                        <input class="uk-input" type="tel" pattern="0[0-9]{9}" placeholder="0123456789..." v-model="customer.phone" required/>
                        <p class="phone-helper">Indiquez le numéro de téléphone au format 0123456789</p>
                      </div>
                      <div class="uk-width-1-1">
                        <label>Email *</label>
                        <input class="uk-input" type="email" v-model="customer.email" required />
                      </div>
                      <div class="uk-width-1-1">
                        <label>Adresse</label>
                        <input class="uk-input" type="text" v-model="customer.address.addressLine1" />
                      </div>
                      <div class="uk-width-1-1">
                        <vk-grid gutter="small" class="uk-child-width-expand">
                          <div class="select uk-width-1-4@m">
                            <label>Code postal</label>
                            <input class="uk-input" type="text" v-model="customer.address.postalCode" maxlength="7" />
                          </div>
                          <div>
                            <label>Commune</label>
                            <input class="uk-input" type="text" v-model="customer.address.city"/>
                          </div>
                        </vk-grid>
                      </div>
                    </vk-grid>
                  </div>
                </vk-grid>
              </div>
              <div class="uk-width-1-2@m">
                <vk-grid class="uk-child-width-1-1" gutter="small">
                  <div class="form-section">
                    <h3 v-if="isPerson">Contact secondaire</h3>
                    <h3 v-if="!isPerson">Entreprise</h3>
                    <vk-grid gutter="small" class="uk-child-width-expand">
                      <div class="uk-width-1-4@m" v-if="isPerson">
                        <label>Civilité</label>
                        <select class="uk-select gender" v-model="customer.secondaryContactGender" >
                          <option :value="null">Choisir</option>
                          <option :value="gender.value" v-for="gender in genders" :key="gender.value">
                            {{ gender.label }}
                          </option>
                        </select>
                      </div>
                      <div class="uk-width-3-4@m" v-if="isPerson">
                        <label>Nom</label>
                        <input class="uk-input" type="text" v-model="customer.secondaryContactLastname" />
                      </div>
                      <div class="uk-width-1-2" v-if="isPerson">
                        <label>Prénom</label>
                        <input class="uk-input" type="text" v-model="customer.secondaryContactFirstname" />
                      </div>
                      <div class="uk-width-1-2" v-if="isPerson">
                        <label>Profession</label>
                        <input class="uk-input" type="text" v-model="customer.secondaryContactProfession" />
                      </div>
                      <div class="uk-width-1-2" v-if="isPerson">
                        <label>Date de naissance</label>
                        <app-datepicker v-model="customer.secondaryContactBirthDate" />
                      </div>
                      <div class="uk-width-1-2" v-if="isPerson">
                        <label>Lieu de naissance</label>
                        <input class="uk-input" type="text" v-model="customer.secondaryContactBirthPlace" />
                      </div>
                      <div class="uk-width-1-1">
                        <label>Téléphone</label>
                        <input class="uk-input" type="tel" pattern="0[0-9]{9}" placeholder="0123456789..." v-model="customer.secondaryContactPhone"/>
                        <p class="phone-helper">Indiquez le numéro de téléphone au format 0123456789</p>
                      </div>
                      <div class="uk-width-1-1" v-if="!isPerson">
                        <label>Nom *</label>
                        <input class="uk-input" type="text" v-model="customer.companyName" required />
                      </div>
                      <div class="uk-width-1-1" v-if="!isPerson">
                        <label>SIREN *</label>
                        <input class="uk-input" type="text" v-model="customer.companySiret" required />
                      </div>
                      <div class="uk-width-1-1">
                        <label>Email</label>
                        <input class="uk-input" type="email" v-model="customer.secondaryContactEmail" />
                      </div>
                      <div class="uk-width-1-1">
                        <label>Adresse</label>
                        <input class="uk-input" type="text" v-model="customer.secondaryContactAddress.addressLine1" />
                      </div>
                      <div class="uk-width-1-1">
                        <vk-grid gutter="small" class="uk-child-width-expand">
                          <div class="select uk-width-1-4@m">
                            <label>Code postal</label>
                            <input class="uk-input" type="text" v-model="customer.secondaryContactAddress.postalCode" maxlength="7" />
                          </div>
                          <div>
                            <label>Commune</label>
                            <input class="uk-input" type="text" v-model="customer.secondaryContactAddress.city"/>
                          </div>
                        </vk-grid>
                      </div>
                      <div class="uk-width-1-1" v-if="isPerson">
                        <label>Nature de la relation du contact (conjoint, associé...)</label>
                        <input class="uk-input" type="text" v-model="customer.secondaryContactRelationship" />
                      </div>
                    </vk-grid>
                  </div>
                </vk-grid>
              </div>

              <div class="uk-width-1-1@m">
                <vk-grid class="uk-child-width-1-1" gutter="small"><div class="form-section">
                    <h3>Agence de référence</h3>
                    <vk-grid gutter="small" class="uk-child-width-expand">
                      <div class="uk-width-1-2">
                        <label>Agence du client *</label>
                        <select class="uk-select" v-model="customer.agencyId" required>
                          <option :value="null">Sélectionner une agence</option>
                          <option :value="agency.agencyId"
                            v-for="agency in agencies"
                            :key="agency.agencyId">{{ agency.name }}
                          </option>
                        </select>
                      </div>
                    </vk-grid>
                  </div>
                  <div class="form-section" v-if="$route.params.customerId">
                    <h3>Référence</h3>
                    <vk-grid gutter="small" class="uk-child-width-expand">
                      <div class="uk-width-1-1">
                        <label>Référence *</label>
                        <input class="uk-input" type="text" v-model="customer.reference" disabled />
                      </div>
                      <div class="uk-width-1-1">

                      </div>
                    </vk-grid>
                  </div>
                </vk-grid>
              </div>
            </vk-grid>
        </section>
      </div>
      <div class="body" v-else>
        <div class="card spinner-container">
          <app-spinner />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import TabNav from '@/components/layouts/TabNav.vue';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';

import customerApi from '@/services/api/customer';
import agencyApi from '@/services/api/agency';
import postalCodeApi from '@/services/api/postalCode';
import utils from '@/services/utils/utils';

import customerStatuses from '@/services/data/customerStatuses.json';
import customerFirstContacts from '@/services/data/customerFirstContacts.json';
import customerBrandKnowledge from '@/services/data/customerBrandKnowledge.json';

export default {
  name: 'customer',
  components: {
    TabNav,
    Breadcrumb,
  },
  metaInfo() {
    return {
      title: this.customer.lastname ? `${this.customer.lastname} –  Contact` : 'Contact',
    };
  },
  data() {
    return {
      // new data
      isLoading: null,
      agencies: null,
      customerFirstContacts,
      customerBrandKnowledge,
      customerStatuses,

      // old data
      customer: {
        agencyId: null,
        gender: null,
        lastname: null,
        firstname: null,
        profession: null,
        birthDate: null,
        birthPlace: null,
        secondaryContactGender: null,
        secondaryContactFirstname: null,
        secondaryContactLastname: null,
        secondaryContactProfession: null,
        secondaryContactBirthDate: null,
        secondaryContactBirthPlace: null,
        secondaryContactEmail: null,
        secondaryContactAddress: {
          addressLine1: null,
          postalCode: null,
          city: null,
        },
        secondaryContactRelationship: null,
        type: 'COMPANY',
        companyName: null,
        companySiret: null,
        address: {
          addressLine1: null,
          postalCode: null,
          city: null,
        },
        status: 'UNQUALIFIED',
        firstContact: 'MANUAL_AGENCY_CREATION',
        firstContactDate: null,
        buildingCities: null,
        deadline: null,
        budget: null,
        brandKnowledge: 'UNKNOWN',
      },
      genders: [
        { value: 'MALE', label: 'M.' },
        { value: 'FEMALE', label: 'Mme' },
      ],
      types: ['COMPANY', 'PERSON'],
      isPerson: false,
      postalCodes: null,
      fullPostalCodeSelection: null,
      disablePostalCodeSelection: false,
    };
  },
  watch: {
    isPerson() {
      this.customer.type = this.isPerson ? 'PERSON' : 'COMPANY';
    },
    'customer.buildingCities': {
      handler() {
        if (this.customer.buildingCities && this.customer.buildingCities.length >= 3) {
          this.disablePostalCodeSelection = true;
        } else {
          this.disablePostalCodeSelection = false;
        }
      },
    },
  },
  computed: {
    routeStatus() {
      // on déduit les liens, à partir de la route actuelle
      const routeStatus = this.$route.name.split('-')[1];
      if (routeStatus === 'unqualified' || routeStatus === 'prospects') {
        return `customers-${routeStatus}`;
      }
      return 'projects-customers';
    },
    tagColor() {
      return {
        'tag-primary': this.customer.status === 'CLIENT',
        'tag-secondary': this.customer.status !== 'CLIENT' && this.customer.status !== 'UNQUALIFIED',
        'tag-neutral': this.customer.status === 'UNQUALIFIED',
      };
    },
    tabs() {
      // initialisation des tabs
      const tabs = [
        {
          route: {
            name: `${this.routeStatus}-customerId`,
            params: { customerId: null },
          },
          label: 'Informations',
        },
        {
          route: {
            name: `${this.routeStatus}-customerId-draft-projects`,
            params: { customerId: null },
          },
          label: 'Avant-projets',
        },
        {
          route: {
            name: `${this.routeStatus}-customerId-contact`,
            params: { customerId: null },
          },
          label: 'Prise de contact',
        },
        {
          route: {
            name: `${this.routeStatus}-customerId-agenda`,
            params: { customerId: null },
          },
          label: 'Agenda',
        },
      ];
      // Pour les clients, on a ajoute les projets
      if (this.routeStatus === 'projects-customers') {
        tabs.push(
          {
            route: {
              name: `${this.routeStatus}-customerId-projects`,
              params: { customerId: null },
            },
            label: 'Projets',
          },
        );
      }
      return tabs.map((el) => ({
        route: {
          name: el.route.name,
          params: { customerId: this.$route.params.customerId },
        },
        label: el.label,
      }));
    },
  },
  async mounted() {
    await this.getData();
    await this.postalCodeChange();
    await this.searchChange();
    if (this.routeStatus === 'projects-customers') {
      // On set la valeur du status par défaut à 'Client'
      this.customer.status = 'CLIENT';
    }
  },
  methods: {
    async postalCodeChange() {
      if (this.customer && this.customer.buildingCities && this.customer.buildingCities.length) {
        const promiseFullPostalCodes = this.customer.buildingCities.map((postalCodeInseeNumber) => postalCodeApi.getOne(postalCodeInseeNumber));
        this.fullPostalCodeSelection = await Promise.all(promiseFullPostalCodes);
      }
    },
    async searchChange(search) {
      try {
        let postalCodes = null;
        if (this.customer && this.customer.agencyId) {
          postalCodes = await postalCodeApi.getByAgency(this.customer.agencyId, search);
        } else {
          postalCodes = await postalCodeApi.getAll(search);
        }

        let parsedlPostalCodes = utils.formatOptions(
          postalCodes,
          (o) => o.postalCodeInseeNumber,
          (o) => `${o.city} - ${o.postalCodeInseeNumber.split('-')[0]}`,
        );

        if (this.fullPostalCodeSelection && this.fullPostalCodeSelection.length) {
          const parsedFullPostalCodes = utils.formatOptions(
            this.fullPostalCodeSelection,
            (o) => o.postalCodeInseeNumber,
            (o) => `${o.city} - ${o.postalCodeInseeNumber.split('-')[0]}`,
          );

          parsedlPostalCodes = parsedlPostalCodes.filter((postalCode) => !parsedFullPostalCodes.find((pc) => pc.name === postalCode.name));
          this.postalCodes = parsedFullPostalCodes.concat(parsedlPostalCodes);
        } else {
          this.postalCodes = parsedlPostalCodes;
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la recherche de codes postaux',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getData() {
      this.isLoading = true;
      await this.getAgencies();

      if (this.$route.params.customerId) {
        await this.getCustomer();

        this.isPerson = this.customer.type === 'PERSON';
        this.customer.type = this.isPerson ? 'PERSON' : 'COMPANY';
      } else {
        this.isPerson = true;
        this.customer.agencyId = this.agencies[0].agencyId;
      }
      this.isLoading = false;
    },
    async getAgencies() {
      try {
        const allAgencies = await agencyApi.getAll();

        if (allAgencies && allAgencies.length > 0) {
          this.agencies = allAgencies.sort((a, b) => a.name.localeCompare(b.name));
        } else {
          this.agencies = [];
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des agences',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    async getCustomer() {
      this.isLoading = true;
      try {
        if (this.$route.params.customerId) {
          this.customer = await customerApi.getById(this.$route.params.customerId);

          if (!this.customer.address) {
            this.customer.address = {
              addressLine1: null,
              postalCode: null,
              city: null,
            };
          }
          if (!this.customer.secondaryContactAddress) {
            this.customer.secondaryContactAddress = {
              addressLine1: null,
              postalCode: null,
              city: null,
            };
          }
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du contact',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
    async save() {
      this.isLoading = true;
      try {
        if (this.$route.params.customerId) {
          const formatedCustomer = { ...this.customer };
          delete formatedCustomer.customerId;
          delete formatedCustomer.createdAt;
          delete formatedCustomer.updatedAt;
          delete formatedCustomer.deletedAt;

          this.customer = await customerApi.update(this.$route.params.customerId, formatedCustomer);
          this.$notification.show({ text: 'Ce contact a été modifié avec succès !' });
        } else {
          this.customer = await customerApi.create(this.customer);
          this.$notification.show({ text: 'Ce contact a été ajouté avec succès !' });

          let routeStatus = '';
          if (this.customer.status === 'UNQUALIFIED') {
            routeStatus = 'customers-unqualified-customerId';
          } else if (this.customer.status === 'CLIENT') {
            routeStatus = 'projects-customers-customerId';
          } else {
            routeStatus = 'customers-prospects-customerId';
          }

          this.$router.push({ name: routeStatus, params: { customerId: this.customer.customerId } });
        }
      } catch (er) {
        if (er.response.status === 400 && er.response.data.message[0] === 'Not a valid phone number') {
          this.$message.show({
            title: 'Erreur',
            text: 'Le numéro de téléphone n\'est pas valide',
            cancelText: 'Ok',
            hasCancel: true,
          });
        } else {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de la sauvegarde du contact',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
      }
      this.isLoading = false;
    },
    openDeleteModal() {
      this.$message.show({
        title: 'Supprimer le contact',
        text: 'Êtes vous sûr de vouloir supprimer ce contact ?',
        confirmText: 'Supprimer le contact',
        hasConfirm: true,
        cancelText: 'Annuler',
        hasCancel: true,
        onConfirm: () => {
          this.deleteCustomer();
        },
      });
    },
    async deleteCustomer() {
      if (this.$route.params.customerId) {
        this.isLoading = true;
        try {
          await customerApi.delete(this.$route.params.customerId);
          this.$notification.show({ text: 'Ce contact a été supprimé avec succès !' });

          this.$router.push({ name: 'customers-unqualified' });
        } catch (er) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu un problème lors de la suppression du contact',
            cancelText: 'Ok',
            hasCancel: true,
          });
        }
        this.isLoading = false;
      }
    },
    getCustomerStatusName(status) {
      const customer = customerStatuses.find((el) => el.name === status);
      return customer ? customer.label : null;
    },
  },
};
</script>

<style lang='sass' scoped>
.customer
  .body
    @include screen
  .new-header
    @include header
  .row
    @include row
  .breadcrumbs
    @include breadcrumbs
  .spinner-container
    @include spinner-container
  .card
    @include card
  h3
    color: $primary
    margin-bottom: 10px
  .tag-container
    display: flex
    align-items: center
    margin-left: 16px
    > h5
      height: fit-content
  .tag-primary
    @include tag-primary
  .tag-secondary
    @include tag-secondary
  .save
    margin-left: 16px
  .delete-modal
    .cancel
      margin-right: 16px
    p
      text-align: center
  .form-section
    margin-bottom: 10px
    .info
      display: block
      margin-top: 2px
      padding: 10px 16px
      background-color: $gray-white
      border-radius: 4px
  @media (min-width: 960px)
    .notes-right
      margin-top: 62px
</style>

<style lang="scss">
.customer-page {
   ~ .customer-page {
    margin-top: 20px;
   }
  label {
    display: block;
    @include heading-xs;
    color: $label;
    margin-bottom: 5px;
  }
  input, select {
    margin: 0;
    padding: 5px;
    height: 35px;
    border-radius: $global-border-radius;
    border: 1px solid $gray-light;
    @include paragraph
  }
  .app-select {
    .app-search {
      .search {
        bottom: 10px;
      }
      .cancel {
        bottom: 12px;
      }
      input {
        margin-top: 2px;
        padding: 10px 16px 10px 42px;
        box-sizing: border-box;
      }
    }
    .select {
      margin-top: 2px;
      font-weight: 400;
    }
    .options-container {
        .app-checkbox {
          label {
            display: flex;
          }
        }
      }
  }
  .header {
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 5px;
    box-shadow: 1px 1px 1px 1px rgba(0,0,0, 0.1);
    background-color: $white;
    .person {
      display: flex;
      font-weight: 500;
      > label:not(.vue-js-switch) {
        margin: 0 10px 0 0;
        @include paragraph;
        color: $gray-dark;
      }
    }
  }
  .wrapper {
    border-radius: 5px;
    box-shadow: 1px 1px 1px 1px rgba(0,0,0, 0.1);
    background-color: $white;
    .phone-helper {
      margin-top: 0.5rem;
      color: $label;
      @include label;
    }
  }
}
</style>
